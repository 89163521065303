import { setCacheNameDetails } from 'workbox-core';
import { precacheAndRoute } from 'workbox-precaching';

setCacheNameDetails({ prefix: 'dlog' });
precacheAndRoute(self.__WB_MANIFEST);

caches.keys().then(keys => {
    keys.forEach(key => {
        caches.open(key).then(cache => {
            cache.keys().then(cacheKeys => {
                cacheKeys.forEach(cacheKey => {
                    if (cacheKey.url.includes('/index.html')) {
                        cache.delete(cacheKey);
                    }
                })
            })
        })
    })
})